@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #root {
    @apply h-full overflow-hidden;
  }
  #root {
    @apply flex flex-col bg-gray-800 text-green-500 font-mono;
  }
  button,
  input {
    @apply focus:outline-none;
  }
}
